import { Link } from "gatsby"
import React, { useState } from "react"
import { Button } from "./button"

export interface SidebarProps {
  competitionSlug: string
  navigation: {
    [key: string]: {
      name: string
      visible: boolean
      url: string
      link?: string
    }
  }
}

export const Sidebar: React.FC<SidebarProps> = ({
  competitionSlug,
  navigation,
}) => {
  const [open, setOpen] = useState(false)

  return (
    <aside className="px-5 mb-5">
      <Button
        color="gray-100"
        textColor="text"
        classNames="mb-5 md:hidden"
        to={null}
        onClick={() => setOpen(!open)}
      >
        <svg
          style={{ width: 24, height: 24 }}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
      </Button>

      <nav className={`bg-gray-100 py-5 ${open ? "block" : "hidden"} md:block`}>
        <ul className="border-b border-teal mb-4 pb-4">
          <li className="px-5 mb-3">
            <Link to={`/competition/${competitionSlug}`}>Enter Now</Link>
          </li>
          <li className="px-5 mb-3">
            {LinkForPage(competitionSlug, navigation["how_to_pay"])}
          </li>
          <li className="px-5 mb-3">
            {LinkForPage(competitionSlug, navigation["how_to_play"])}
          </li>
          <li className="px-5">
            {LinkForPage(competitionSlug, navigation["charities"])}
          </li>
        </ul>

        <ul className="border-b border-teal mb-4 pb-4">
          <li className="px-5 mb-3">
            <Link to={`/competition/${competitionSlug}/latest-updates`}>
              Latest Updates
            </Link>
          </li>
          <li className="px-5 mb-3">
            {LinkForPage(competitionSlug, navigation["entries"])}
          </li>
          <li className="px-5 mb-3">
            {LinkForPage(competitionSlug, navigation["prize_fund"])}
          </li>
          <li className="px-5 mb-3">
            {LinkForPage(competitionSlug, navigation["golfers"])}
          </li>
          <li className="px-5 mb-3">
            {LinkForPage(competitionSlug, navigation["mini_leagues"])}
          </li>
          <li className="px-5">
            {LinkForPage(competitionSlug, navigation["extra_page_1"])}
          </li>
        </ul>

        <ul className="border-b border-teal mb-4 pb-4">
          <li className="px-5 mb-3">
            {LinkForPage(competitionSlug, navigation["thursday"])}
          </li>
          <li className="px-5 mb-3">
            {LinkForPage(competitionSlug, navigation["friday"])}
          </li>
          <li className="px-5 mb-3">
            {LinkForPage(competitionSlug, navigation["saturday"])}
          </li>
          <li className="px-5 mb-3">
            {LinkForPage(competitionSlug, navigation["sunday"])}
          </li>
          <li className="px-5">
            {LinkForPage(competitionSlug, navigation["extra_page_2"])}
          </li>
        </ul>

        <ul>
          <li className="px-5 mb-3">
            {LinkForPage(competitionSlug, navigation["under_par"])}
          </li>
          <li className="px-5 mb-3">
            {LinkForPage(competitionSlug, navigation["par"])}
          </li>
          <li className="px-5 mb-3">
            {LinkForPage(competitionSlug, navigation["birdies"])}
          </li>
          <li className="px-5 mb-3">
            {LinkForPage(competitionSlug, navigation["eagles"])}
          </li>
          <li className="px-5 mb-3">
            {LinkForPage(competitionSlug, navigation["top_21"])}
          </li>
          <li className="px-5 mb-3">
            {LinkForPage(competitionSlug, navigation["hole_matrix"])}
          </li>
          <li className="px-5 mb-3">
            {LinkForPage(competitionSlug, navigation["extra_page_3"])}
          </li>
          <li className="px-5 mb-3">
            {LinkForPage(competitionSlug, navigation["extra_page_4"])}
          </li>
          <li className="px-5 mb-3">
            {LinkForPage(competitionSlug, navigation["extra_page_5"])}
          </li>
          <li className="px-5">
            {LinkForPage(competitionSlug, navigation["extra_page_6"])}
          </li>
        </ul>
      </nav>
    </aside>
  )
}

function LinkForPage(
  slug: string,
  page: SidebarProps["navigation"][string]
): JSX.Element | null {
  if (!page.visible && !page.link) return null

  if (!page.visible)
    return (
      <Link to={null} className={"line-through"}>
        {page.name}
      </Link>
    )
  return <Link to={`/competition/${slug}/${page.url}`}>{page.name}</Link>
}
