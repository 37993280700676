import React from "react"
import Icon from "../assets/golf-ball-solid.svg"
import { Button } from "./button"
import logo from "../images/logolong.png"
import { graphql, Link, StaticQuery } from "gatsby"

export interface HeaderProps {
  hideLogo?: boolean
  latestButton?: boolean
}

export const Header: React.FC<HeaderProps> = ({
  hideLogo,
  latestButton = true,
}) => {
  return (
    <StaticQuery
      query={query}
      render={data => {
        const latestCompetition = data.competitions.edges[0]
        return (
          <header className="bg-green color-white p-5">
            <div className="container mx-auto">
              <div className="flex flex-row items-center justify-between">
                <Link to="/" className="flex-1">
                  <Icon width="32px" className="self-start inline text-white" />
                </Link>

                {!hideLogo && (
                  <Link to="/" className="flex-1 text-center hidden md:block">
                    <img src={logo} width="300" className="inline" />
                  </Link>
                )}

                <nav className="flex-1 text-right">
                  <Button to={`/contact`} color="gray-100">
                    <svg
                      style={{ width: 24, height: 24 }}
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      className="md:hidden"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                      />
                    </svg>
                    <span className="hidden md:block">Contact</span>
                  </Button>
                </nav>
              </div>

              {!hideLogo && (
                <div className="text-center">
                  <Link to="/" className="md:hidden inline">
                    <img src={logo} width="300" className="inline" />
                  </Link>
                </div>
              )}
            </div>
          </header>
        )
      }}
    ></StaticQuery>
  )
}

const query = graphql`
  {
    competitions: allPrismicCompetition(
      sort: { fields: data___start_date, order: DESC }
    ) {
      edges {
        node {
          id
          uid
          first_publication_date
          data {
            title {
              text
            }
            start_date
            end_date
            banner_image {
              url
            }
          }
        }
      }
    }
  }
`
