import { format } from "date-fns"
import { graphql, Link } from "gatsby"
import React from "react"
import { Button } from "../components/button"
import { Layout } from "../components/layout"
import logo from "../images/logo.png"
import masters2019 from "../images/masters2019.jpg"

export const IndexPage: React.FC<any> = ({ data }) => {
  const { contact, competitions } = data

  const [latestCompetition, ...pastCompetitions] = competitions.edges

  return (
    <Layout header={{ hideLogo: false }} competitions={competitions}>
      <div className="mt-8 mx-auto container px-5 lg:px-0 flex flex-row justify-between">
        <div>
          <h1 className="font-primary font-black text-3xl">
            {contact.data.title.text}
          </h1>

          <div
            className="pt-5 cmsContent"
            dangerouslySetInnerHTML={{ __html: contact.data.content.html }}
          />
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  {
    contact: prismicContact {
      data {
        title {
          text
        }
        content {
          html
        }
      }
    }
    competitions: allPrismicCompetition(
      sort: { fields: first_publication_date, order: DESC }
    ) {
      edges {
        node {
          id
          uid
          first_publication_date
          data {
            body1 {
              ...Charities
            }
            title {
              text
            }
            description {
              html
            }
            start_date
            end_date
            banner_image {
              url
            }
            card_image {
              url
            }
          }
        }
      }
    }
  }

  fragment Charities on PrismicCompetitionBody1Charity {
    primary {
      charity_name {
        text
      }
      logo {
        url
      }
    }
  }
`
