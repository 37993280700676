import { Link } from "gatsby"
import React from "react"
import { Footer } from "./footer"
import { Header, HeaderProps } from "./header"
import { Sidebar, SidebarProps } from "./sidebar"

interface LayoutProps {
  header?: HeaderProps
  sidebar?: SidebarProps
  competition?: any
  competitions?: any
  includeSponsers?: boolean
}

export const Layout: React.FC<LayoutProps> = ({
  header,
  children,
  competitions,
  includeSponsers,
}) => {
  const charities = {}
  competitions.edges.map(c =>
    c.node.data.body1.map(charity => {
      charities[charity.primary.charity_name.text] = {
        name: charity.primary.charity_name.text,
        logo: charity.primary.logo.url,
      }
    })
  )

  return (
    <>
      <Header {...header} />
      <div>{children}</div>
      <Footer
        charities={Object.values(charities)}
        includeSponsers={includeSponsers}
      />
    </>
  )
}

export const SidebarLayout: React.FC<LayoutProps> = ({
  header,
  sidebar,
  children,
  competition,
  includeSponsers,
}) => {
  const charities =
    competition?.body1.map(charity => ({
      name: charity.primary.charity_name.text,
      logo: charity.primary.logo.url,
    })) ?? []

  return (
    <>
      <Header {...header} />
      <div className="container mx-auto mt-8">
        <div className="grid grid-cols-1 lg:grid-cols-sidebar lg:gap-x-2">
          <div>
            <Sidebar {...sidebar} />
          </div>
          <div>{children}</div>
        </div>
      </div>
      <Footer charities={charities} includeSponsers={includeSponsers} />
    </>
  )
}
