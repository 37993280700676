import React from "react"
import { Link } from "gatsby"

interface ButtonProps {
  to: string
  color: "white" | "gray-100" | "gray-500" | "gray-900" | "teal" | "green"
  textColor?: "white" | "text"
  classNames?: string
  onClick?: () => void
}

export const Button: React.FC<ButtonProps> = ({
  to,
  color,
  children,
  textColor = "text",
  classNames,
  onClick,
}) => {
  if (!to) {
    return (
      <button
        className={`bg-${color} px-8 py-3 rounded font-bold text-${textColor} font-primary inline-block ${classNames}`}
        onClick={onClick}
      >
        {children}
      </button>
    )
  }

  return (
    <Link
      className={`bg-${color} px-8 py-3 rounded font-bold text-${textColor} font-primary inline-block ${classNames}`}
      to={to}
      onClick={onClick}
    >
      {children}
    </Link>
  )
}
